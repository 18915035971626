/* eslint-disable no-unreachable */
import Button from '/components/Button';
import Clickable from '/components/Clickable';
import FilterPanel from '/components/collections/FilterPanel';
import HeapService from '/services/analytics/heap';
import HotProductsAlgolia from '/components/lp/sections/HotProductsAlgolia';
import Pagination from '/components/Pagination';
import ProductCard from '/components/product-card/ProductCard';
import ProductFacets from '/components/collections/ProductFacets';
import PropTypes from 'prop-types';
import SelectGroup from '/components/SelectGroup';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { observer } from 'mobx-react-lite';
import { setQueryId } from '/services/analytics/algolia';
import { trackFilterClick } from '/services/analytics/shinesty-analytics';
import { useGlobalStore } from '/state/global-context';
import { useRouter } from 'next/router';

import { SORT_OPTIONS, USE_ALGOLIA_BACKUPS } from '/services/algolia/constants';
import {
	clearSelectedFacets,
	convertFacetsToHash,
	formatFacetGroups,
	getSelectedFacets,
} from '/utils/product-facets';
import { trackSearchPage, trackViewItemList } from '/services/analytics/shinesty-analytics';
import { useEffect, useState } from 'react';

const ProductListing = ({
	algoliaResponse,
	collectionHandle,
	pageType,
	productCardClassNames,
	searchTerm,
}) => {
	const globalStore = useGlobalStore();
	const router = useRouter();
	let sortTerm = 'most relevant';
	if (collectionHandle == 'influencer-picks') {
		sortTerm = 'title';
	}
	if (router?.query?.sort) {
		sortTerm = router?.query?.sort;
	}

	const DEFAULT_SEARCH_SETTINGS = {
		page: Number(router?.query?.page) || 1,
		searchTerm: searchTerm || router?.query?.q || null,
		sort: sortTerm,
	};
	const selectedFacets = convertFacetsToHash(router?.query?.selectedFacets);
	const [facetGroups, setFacetGroups] = useState();
	const [filtersVisible, setFiltersVisible] = useState(false);
	const [filterPanelOpen, setFilterPanelOpen] = useState(false);
	const [isPageLoading, setIsPageLoading] = useState(true);
	const [searchSettings, setSearchSettings] = useState(DEFAULT_SEARCH_SETTINGS);
	const products = algoliaResponse?.hits || [];

	useEffect(() => {
		setQueryId(algoliaResponse.queryID);
		const handleUpdate = async () => {
			setIsPageLoading(true);
			setSearchSettings(DEFAULT_SEARCH_SETTINGS);
		};
		const formattedFacets = formatFacetGroups(algoliaResponse?.facets, selectedFacets);
		setFacetGroups(formattedFacets);
		handleUpdate();
	}, [collectionHandle]);

	useEffect(() => {
		if (!searchTerm) {
			return;
		}
	}, [searchTerm]);

	useEffect(() => {
		if (!products[0]?.handle) {
			return;
		}
		if (pageType === 'collection') {
			trackViewItemList(products, collectionHandle);
		}

		if (pageType === 'search') {
			trackSearchPage(products, searchTerm);
		}
	}, [products]);

	const onFacetChange = (facetGroupKey, facetTitle, isChecked) => {
		const newFacetGroups = [...facetGroups];

		if (isChecked) {
			HeapService.trackEvent('Filter Added', { facet: facetTitle });
		} else {
			HeapService.trackEvent('Filter Removed', { facet: facetTitle });
		}

		const facetGroupIndex = newFacetGroups.findIndex((group) => {
			return group.key === facetGroupKey;
		});

		if (facetGroupIndex === -1 || !newFacetGroups[facetGroupIndex].facets) {
			return;
		}

		const facetIndex = newFacetGroups[facetGroupIndex].facets.findIndex((facet) => {
			return facet.title === facetTitle;
		});

		if (facetIndex === -1) {
			return;
		}

		newFacetGroups[facetGroupIndex]['facets'][facetIndex].isSelected = isChecked;

		setFacetGroups(newFacetGroups);

		const selectedFacets = getSelectedFacets(newFacetGroups);
		updateSearchSettings('selectedFacets', selectedFacets.keys);
		trackFilterClick(selectedFacets.keys);
	};

	const onFacetClear = () => {
		let newFacetGroups = [...facetGroups];
		newFacetGroups = clearSelectedFacets(newFacetGroups);
		setFacetGroups(newFacetGroups);
	};

	// Handles updating the current search settings, which extend the default search settings
	const updateSearchSettings = (key, value) => {
		if (searchSettings[key] === value) {
			return;
		}

		const newSearchSettings = { ...searchSettings };

		if (typeof value === 'undefined' && newSearchSettings[key]) {
			delete newSearchSettings[key];
		}

		newSearchSettings[key] = value;

		// reset the page if the user changes their sort or filtering
		if (key !== 'page') {
			newSearchSettings.page = 1;
		}
		router.push({ pathname: window.location.pathname, query: newSearchSettings });
		setSearchSettings(newSearchSettings);
	};

	//
	// Early exit to eliminate functionality on the collection page if we're not using algolia
	// TODO: Support some type of pagination/filtering with shopify direct calls?
	//
	if (USE_ALGOLIA_BACKUPS) {
		return (
			<div className="flex flex-wrap justify-start md:m-2">
				{products.map((product, i) => {
					return <ProductCard key={i} priority={i < 5} product={product} rank={i} />;
				})}
			</div>
		);
	}

	// Handle 0 product count and 0 products
	if (!products || products.length === 0) {
		return (
			<div className="text-center pt-5">
				<Typography component="div" variant="subtitle-lg">
					Sorry, you&apos;ve stumped us.
				</Typography>
				<div className="border-y-2 m-5 p-5">
					<Typography component="h4" variant="heading-sm">
						Check out some great products instead:
					</Typography>
					<HotProductsAlgolia config={{ isCarousel: false }} collection={'guys-boxers'} />
				</div>
			</div>
		);
	}

	// The default product listing with filters, sort, etc.
	return (
		<div className="flex flex-col">
			{/* Mobile Filter Toggle */}
			{pageType !== 'landing' && (
				<>
					<div
						className={classNames(
							'sticky lg:hidden uppercase w-full z-20 text-center mb-4 p-2 bg-white',
							globalStore.topPositionPageMobile,
						)}>
						<Clickable
							className="w-full py-3 text-gray-600"
							variant="round-outlined"
							color="gray-600"
							heapEventData={{
								'Filters Open': 'true',
							}}
							heapEventName="Show Filters"
							onClick={() => setFilterPanelOpen(!filterPanelOpen)}>
							<Typography variant="heading-sm">Sort &amp; Filter</Typography>
						</Clickable>
					</div>
					<div
						className={classNames(
							'hidden lg:flex flex-col sticky bg-white z-20 border-b justify-between py-2 shadow-sm md:flex-row-reverse',
							globalStore.topPositionPage,
						)}>
						{/* Sort Dropdown */}
						<div className="mt-1.5">
							<div className="w-72">
								<span className="bg-white px-1 absolute top-1 right-60 h-4 text-[13px] z-20">
									Sort by
								</span>
								<SelectGroup
									defaultValue={searchSettings.sort || DEFAULT_SEARCH_SETTINGS.sort}
									name="Sort By"
									onChange={(val) => updateSearchSettings('sort', val)}
									options={Object.keys(SORT_OPTIONS).reduce((arr, key) => {
										arr.push({ value: key, label: key });
										return arr;
									}, [])}
									placeholder="Sort By"
									variant="medium"
								/>
							</div>
						</div>

						{/* Desktop Filter Toggles */}
						<div className="w-64 flex-none">
							{filtersVisible ? (
								<Button
									onClick={() => setFiltersVisible(false)}
									title="hide filter options"
									type="button"
									variant="naked">
									Hide Filters
								</Button>
							) : (
								<Clickable
									heapEventData={{
										'Filters Open': 'true',
									}}
									heapEventName="Show Filters"
									onClick={() => setFiltersVisible(true)}
									title="show filter options"
									type="button"
									variant="naked">
									Show Filters
								</Clickable>
							)}
						</div>
					</div>
				</>
			)}

			{/* Product Cards */}
			<div className="flex flex-col relative md:flex-row pt-3">
				{filtersVisible && (
					<div className="hidden lg:block flex-none w-64">
						<div className={classNames('sticky', globalStore.topPositionFacets)}>
							<div className="h-[90vh] overflow-scroll">
								<ProductFacets
									collection={collectionHandle}
									facetGroups={facetGroups}
									onFacetChange={(facetGroupKey, facetKey, isChecked) => {
										onFacetChange(facetGroupKey, facetKey, isChecked);
									}}
									onFacetClear={onFacetClear}
									showSelectedFacetPills={true}
								/>
							</div>
						</div>
					</div>
				)}
				<ul
					role="list"
					className="mx-4 sm:mx-6 grid grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-3 lg:mx-0 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-x-0">
					{products.map((product, i) => {
						return (
							<ProductCard
								// this will get removed but is goign to make a rebase impossible
								backgroundTestActive={false}
								className={productCardClassNames}
								forceLoading={isPageLoading}
								key={i}
								priority={i < 5}
								product={product}
								rank={i}
							/>
						);
					})}
				</ul>
			</div>

			{/* Pagination */}
			<div className="my-4">
				<Pagination
					numberOfPages={algoliaResponse?.nbPages}
					onChange={(val) => {
						updateSearchSettings('page', val);
					}}
					page={searchSettings.page}
				/>
			</div>

			{/* The Mobile Filter Pane */}
			<div className="block lg:hidden">
				<FilterPanel
					closeCallback={() => setFilterPanelOpen(false)}
					facetGroups={facetGroups}
					isOpen={filterPanelOpen}
					onFacetChange={(facetGroupKey, facetKey, isChecked) => {
						onFacetChange(facetGroupKey, facetKey, isChecked);
					}}
					onFacetClear={onFacetClear}
					onSortChange={(val) => updateSearchSettings('sort', val)}
					selectedFacetCount={selectedFacets.length}
					sort={searchSettings.sort}
				/>
			</div>
		</div>
	);
};

ProductListing.propTypes = {
	algoliaResponse: PropTypes.object,
	collectionHandle: PropTypes.string,
	// facet call response before loading
	facets: PropTypes.object,
	// how many results per page
	pageSize: PropTypes.number,
	// 'collection' or 'search' - handles updating analytics
	pageType: PropTypes.string.isRequired,
	productCardClassNames: PropTypes.string,
	searchTerm: PropTypes.string,
};

export default observer(ProductListing);
