import { SIZES_ALL } from '/services/static/sizes';
import { TAG_BASED_FACETS } from '/services/algolia/constants';
import { FACETS as facetMap } from '/services/algolia/constants';

import { sortBy, sortByOrder } from '/utils/common-utils';

const clearSelectedFacets = (facetGroups) => {
	return facetGroups.map((facetGroup) => {
		if (!facetGroup.facets) {
			return facetGroup;
		}

		facetGroup.facets.forEach((facet) => {
			facet.isSelected = false;
		});

		return facetGroup;
	});
};

const convertFacetsToHash = (selectedFacets) => {
	if (!selectedFacets) {
		return {};
	}

	if (typeof selectedFacets === 'string') {
		selectedFacets = selectedFacets.split(',');
	}

	const formatted = selectedFacets.reduce((obj, facetPair) => {
		const facetTuple = facetPair.split(':');

		if (!obj[facetTuple[0]]) {
			obj[facetTuple[0]] = [];
		}

		obj[facetTuple[0]].push(facetTuple[1]);

		return obj;
	}, {});

	return formatted;
};

const getSelectedFacets = (facetGroups) => {
	return facetGroups.reduce(
		(memo, facetGroup) => {
			if (!facetGroup.facets) {
				return memo;
			}

			facetGroup.facets.forEach((facet) => {
				if (facet.isSelected) {
					memo.keys.push(`${facetGroup.key}:${facet.title}`);
					memo.facets.push(facet);
				}
			});

			return memo;
		},
		{ facets: [], keys: [] },
	);
};

const facetSorter = (arr, type) => {
	switch (type) {
		case 'named_tags.Gender' || 'named_tags.gender':
			return arr;
		case '_availableSizes.ecommerce':
			return sortByOrder(arr, SIZES_ALL);
		default:
			return sortBy(arr);
	}
};

const formatFacets = (groupKey, facets, selectedFacets) => {
	const sortedKeys = facetSorter(Object.keys(facets), groupKey);
	const tagFilter = /.*:\s(.*)/;

	return sortedKeys.reduce((memo, facetKey) => {
		memo.push({
			count: facets[facetKey],
			groupKey,
			groupTitle: facetMap[groupKey],
			isDisabled: false,
			isSelected: (selectedFacets && selectedFacets.includes(facetKey)) || false,
			key: facetKey,
			title: facetKey.replace(tagFilter, '$1'),
		});

		return memo;
	}, []);
};

const formatFacetGroups = (algoliaFacets, selectedFacets = {}) => {
	const facetGroups = { ...algoliaFacets };

	// format the facets from algolia
	const formatted = Object.keys(facetGroups).reduce((memo, algoliaFacetKey) => {
		//if the facets are the tags we need to reformat
		if (algoliaFacetKey == 'tags') {
			const tagFacets = Object.keys(TAG_BASED_FACETS);
			tagFacets.map((tag) => {
				const filteredObj = Object.fromEntries(
					Object.entries(algoliaFacets.tags).filter(([key]) => key.includes(tag)),
				);
				memo.push({
					facets: formatFacets(algoliaFacetKey, filteredObj, selectedFacets[algoliaFacetKey]),
					key: tag,
					title: TAG_BASED_FACETS[tag],
				});
			});
		} else {
			memo.push({
				facets: formatFacets(
					algoliaFacetKey,
					algoliaFacets[algoliaFacetKey],
					selectedFacets[algoliaFacetKey],
				),
				key: algoliaFacetKey,
				title: facetMap[algoliaFacetKey],
			});
		}

		return memo;
	}, []);

	return formatted;
};

const formatSelectedFacets = (selectedFacets) => {
	let initialFacets = [];
	const formattedFacets = [];

	if (selectedFacets && typeof selectedFacets !== Array) {
		initialFacets.push(selectedFacets);
	} else {
		initialFacets = selectedFacets;
	}

	const tagFacets = Object.keys(TAG_BASED_FACETS);

	initialFacets?.forEach((facet) => {
		if (tagFacets.some((str) => facet.includes(str))) {
			formattedFacets.push(`tags: ${facet.replace(':', ': ')}`);
		} else {
			formattedFacets.push(facet);
		}
	});

	return formattedFacets;
};

const updateFacetAvailability = (queryFacets, facetGroups) => {
	return facetGroups.map((facetGroup) => {
		if (!facetGroup.facets || !queryFacets[facetGroup.key]) {
			return facetGroup;
		}

		facetGroup.facets.forEach((facet) => {
			const updatedFacetCount = queryFacets[facetGroup.key][facet.title];

			if (!updatedFacetCount || updatedFacetCount === 0) {
				facet.isDisabled = true;
			} else {
				facet.isDisabled = false;
				facet.count = updatedFacetCount;
			}
		});

		return facetGroup;
	});
};

module.exports = {
	clearSelectedFacets,
	convertFacetsToHash,
	getSelectedFacets,
	formatFacetGroups,
	formatSelectedFacets,
	updateFacetAvailability,
};
