import Icon from '/components/IconUpdated';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from '/utils/class-names';

function Pagination({ page = 1, numberOfPages = 1, onChange }) {
	let pageArray = [];
	if (numberOfPages < 8) {
		pageArray = new Array(numberOfPages).fill('x').reduce((arr, j, i) => {
			arr.push(i + 1);
			return arr;
		}, []);
	} else {
		pageArray.push(1);
		let start = page - 3 > 1 ? page - 3 : 2;
		if (start > 2) pageArray.push('...');
		let end = page + 3 > numberOfPages - 1 ? numberOfPages - 1 : page + 3;
		for (let i = start; i <= end; i++) {
			pageArray.push(i);
		}
		if (end + 1 < numberOfPages) pageArray.push('...');
		pageArray.push(numberOfPages);
	}

	if (numberOfPages <= 1) {
		return null;
	}

	return (
		<div className="flex justify-center">
			<div>
				{page !== 1 && numberOfPages > 1 && (
					<button
						className="outline-none rounded-full ml-1 h-11 w-11 hover:bg-gray-200 active:bg-gray-200 focus:shadow-inner shadow-primary-500/50 text-primary flex justify-center items-center"
						onClick={() => onChange(page - 1)}
						title="go to previous page">
						<Icon name="caretLeft" height="14px" width="14px" />
					</button>
				)}
			</div>
			<div className="flex-1 flex flex-wrap item-center justify-center max-w-max">
				{pageArray.map((num, i) => {
					return (
						<button
							key={i + num}
							className={classNames(
								'outline-none rounded-full ml-1 h-11 w-11 hover:bg-gray-200 active:bg-gray-200 focus:shadow-inner shadow-primary-500/50',
								page === num && 'border-2 text-primary font-semibold border-primary pt-[5px]',
							)}
							onClick={() => onChange(num)}
							disabled={num === '...'}
							title={`go to page ${num}`}>
							{num}
						</button>
					);
				})}
			</div>
			<div>
				{page !== numberOfPages && numberOfPages > 1 && (
					<button
						className="outline-none rounded-full ml-1 h-11 w-11 hover:bg-gray-200 active:bg-gray-200 focus:shadow-inner shadow-primary-500/50 text-primary flex justify-center items-center"
						onClick={() => onChange(page + 1)}
						title="go to next page">
						<Icon name="caretRight" height="14px" width="14px" />
					</button>
				)}
			</div>
		</div>
	);
}

Pagination.propTypes = {
	numberOfPages: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	page: PropTypes.number,
};

export default Pagination;
