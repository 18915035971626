import Carousel from '/components/Carousel';
import ProductCard from '/components/product-card/ProductCard';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { getCollection } from '/services/algolia';
import { React, useEffect, useState } from 'react';

const HotProductsAlgolia = ({ config, collection }) => {
	const [products, setProducts] = useState([]);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		async function fetchProducts() {
			const products = await getCollection(collection);
			setProducts(products.hits);
			setLoaded(true);
		}
		fetchProducts().catch();
	}, [collection]);

	if (!loaded) {
		return <div className="min-h-[1591px] md:min-h-[815px]"></div>;
	}

	let content = (
		<Carousel
			className="black"
			dots={true}
			slidesToShow={6}
			responsive={[
				{
					breakpoint: 1536,
					settings: {
						slidesToShow: 6,
					},
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
					},
				},
				{
					breakpoint: 640,
					settings: {
						slidesToShow: 2,
					},
				},
			]}
			slidesToScroll={2}>
			{products.slice(0, 8).map((product, i) => {
				return (
					<div className="lg:px-3 h-full" key={i} id="homepageCarousel">
						<ProductCard product={product} variant="mini" rank={i} showQuickShop />
					</div>
				);
			})}
		</Carousel>
	);

	if (!config.isCarousel) {
		content = (
			<div className="mx-4 sm:mx-6 grid grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-3 lg:mx-0 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-x-0">
				{products.slice(0, 8).map((product, i) => {
					return <ProductCard product={product} variant="mini" rank={i} showQuickShop key={i} />;
				})}
			</div>
		);
	}

	return (
		<div className="container max-w-7xl mb-12 w-screen md:w-auto px-4 md:px-0">
			{config.heading && (
				<Typography className="mb-2 text-center md:mb-6" component="h2" variant="subhead-lg">
					{config.heading}
				</Typography>
			)}
			{content}
		</div>
	);
};

HotProductsAlgolia.propTypes = {
	collection: PropTypes.string,
	config: PropTypes.object,
};

export default HotProductsAlgolia;
